<template>
    <v-footer
        color="primary"
        style="z-index:50; position: inherit;"
    >
        <div class="d-flex align-center">
            <div v-if="show_contact_page"  class="px-2">
                <NuxtLink to="/contact">
                    <span class="text-info">Nous contacter</span>
                </NuxtLink>
            </div>
            <div v-if="show_rgpd_page" class="px-2">
                <NuxtLink to="/donnees-personnelles">
                    <span class="text-info">Données personnelles</span>
                </NuxtLink>
            </div>
            <v-spacer />
            <div class="px-2">
                © {{ new Date().getFullYear() }} Hydraclic

                <small class="api-version">
                    <a v-if="releases_url" :href="releases_url" target="_blank" title="Releases notes">{{ api_version }}</a>
                    <template v-else>{{ api_version }}</template>

                    <v-badge
                        v-if="'PRODUCTION'!==api_env"
                        :color="api_env==='LOCAL'?'danger':'warning'"
                        :content="api_env"
                        inline
                    />
                </small>
            </div>
        </div>
    </v-footer>
</template>

<script lang="ts">

import { useConfigStore } from '~/store'

export default {
    setup() {
        const configStore = useConfigStore()
        return {
            configStore,
        }
    },
    data() {
        return {
            show_contact_page: this.configStore.currentConfig.show_contact_page,
            show_rgpd_page: this.configStore.currentConfig.show_rgpd_page,
            api_version: '',
            api_env: 'PRODUCTION',
            releases_url: null,
        }
    },
    mounted() {
        const api = this.$api as ApiService;
        api.setRequest({
            url: '',
            method: 'GET',
        }).then(async (data) => {
            this.api_version = 'v' + data?.pretty_version ?? '?'
            this.api_env = data?.env?.toUpperCase()
            this.releases_url = data?.releases_url
        })
    },
}
</script>
