<template>
    <div class="pa-0">
        <client-only>
            <v-row
                class="h-screen"
                justify="center"
                align="center"
            >
                <v-col cols="12">
                    <v-card
                        class="mx-auto pa-10"
                        elevation="8"
                        max-width="500"
                        rounded="lg"
                    >
                        <v-form
                            validate-on="submit lazy"
                            @submit.prevent="login"
                        >
                            <div class="w-100 text-center">
                                <img
                                    class="mx-auto"
                                    src="@/assets/images/logo.png"
                                    alt="Hydraclic'"
                                >
                            </div>

                            <div class="text-subtitle-1 text-medium-emphasis">
                                Adresse e-mail ou identifiant
                            </div>
                            <v-text-field
                                v-model="email"
                                density="compact"
                                placeholder="Entrer votre adresse e-mail ou identifiant"
                                prepend-inner-icon="mdi-email-outline"
                                variant="outlined"
                            />

                            <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
                                Mot de passe
                            </div>

                            <v-text-field
                                v-model="password"
                                :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                                :type="visible ? 'text' : 'password'"
                                density="compact"
                                placeholder="Entrer votre mot de passe"
                                prepend-inner-icon="mdi-lock-outline"
                                variant="outlined"
                                hide-details
                                @click:append-inner="visible = !visible"
                            />

                            <v-btn
                                variant="text"
                                class="text-caption text-decoration-none text-blue px-1"
                                @click="$router.push('/connexion/mot-de-passe-oublie')"
                            >
                                Mot de passe oublié ?
                            </v-btn>

                            <v-btn
                                block
                                class="my-4"
                                color="primary"
                                size="large"
                                type="submit"
                                :loading="loading"
                            >
                                Se connecter
                            </v-btn>

                            <v-btn
                                block
                                class="my-4"
                                color="primary"
                                size="large"
                                variant="tonal"
                                @click="$router.push('/')"
                            >
                                Retourner à l'accueil
                            </v-btn>
                            <v-btn
                                block
                                class="my-4"
                                color="secondary"
                                size="large"
                                variant="tonal"
                                @click="$router.push('/contact')"
                            >
                                Accéder au formulaire de contact
                            </v-btn>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </client-only>
    </div>
</template>

<script lang="ts">

import { useNotifyStore, useUserStore } from '~/store'
export default {
    name : "Connexion",
    setup() {
        definePageMeta({ layout : 'empty', middleware: ['check-is-user'] })
        useHead({
            title : "Hydraclic | Connexion",
            meta : [
                { name : 'description', content : "Connexion" }
            ]
        })
        const notifyStore = useNotifyStore()
        // const userStore = useUserStore()

        // https://vueuse.org/integrations/useCookies/
        const access_token = useCookie<string>('access_token')
        return {
            access_token,
            notifyStore,
        }
    },
    data: () => ({
        email : null,
        password : null,
        visible : false,

        loading : false,
    }),

    methods : {
        async login () {
            this.loading = true
            this.$api.setRequest({
                url : "login",
                method : "POST",
                body : { email : this.email, username: this.email, password : this.password },
                headers : {
                    'Accept' : 'application/json',
                    'Content-Type' : 'application/json',
                },
            }, true) // true to throw error
                .then((res: { access_token: string; }) => {
                    if (res?.access_token) {
                        this.access_token = res.access_token

                        useUserStore().fetchUser({ value: this.access_token })
                            .then(() => this.$router.push('/tableau-de-bord')) // res : data user
                    } else {
                        this.loading = false
                        if (!this.notifyStore.toasters.length) {
                            this.notifyStore.showMessage({
                                title : 'Échec de la connexion',
                                text : `La connexion a échoué, veuillez vérifier votre identifiant ou mot de passe.`,
                                color : 'error',
                                icon : "mdi-alert-circle"
                            })
                        }
                    }
                }).catch( (error) => {
                    this.loading = false
                    if (error.status === 429) {
                        this.$showToaster({ title: 'Échec de la connexion', text: `Trop de tentatives de connexion ont échoué.<br/> <b>Veuillez réessayer plus tard</b>`, color: 'error', icon: 'mdi-alert-circle', timer: -1 })
                    } else {
                        this.$showToaster({ title: 'Échec de la connexion', text: `La connexion a échoué. <br/> Veuillez vérifier votre identifiant ou mot de passe.`, color: 'error', icon: 'mdi-alert-circle', timer: -1 })
                    }
                })

        }
    },
}
</script>
