
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as formulaireVKNokVtQyUMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/formulaire.vue?macro=true";
import { default as indexMk1OSLtHhqMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/index.vue?macro=true";
import { default as _91k_93B8sguhxbEfMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/administration/acteurs/[k].vue?macro=true";
import { default as acteurs58H65761giMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/administration/acteurs.vue?macro=true";
import { default as configurationUu1R32jE25Meta } from "/builds/datakode/hydraclic-v2/nuxt/pages/administration/configuration.vue?macro=true";
import { default as formulaire2ev2Qs5oSGMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/administration/territoires/[k]/formulaire.vue?macro=true";
import { default as indexTRkQrzzFASMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/administration/territoires/[k]/index.vue?macro=true";
import { default as _91k_930W3AfJ8ubhMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/administration/territoires/[k].vue?macro=true";
import { default as territoiresbBmwTWUlCtMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/administration/territoires.vue?macro=true";
import { default as formulaireciH4yqrmMXMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/formulaire.vue?macro=true";
import { default as indexaonK5dcDbdMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/index.vue?macro=true";
import { default as _91k_93ASY1cbfZBiMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k].vue?macro=true";
import { default as utilisateursDZsQpb389KMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/administration/utilisateurs.vue?macro=true";
import { default as indexx2zUqEHbe3Meta } from "/builds/datakode/hydraclic-v2/nuxt/pages/connexion/index.vue?macro=true";
import { default as _91token_93frk1QGDX9AMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/[token].vue?macro=true";
import { default as indexomCXAG0Q0NMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/index.vue?macro=true";
import { default as mot_45de_45passe_45oublieQsopKuDiNSMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie.vue?macro=true";
import { default as contactO706QCYlfcMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/contact.vue?macro=true";
import { default as donnees_45personnellesMGWia6Lh5jMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/donnees-personnelles.vue?macro=true";
import { default as _91k_93Bu1faCdnx4Meta } from "/builds/datakode/hydraclic-v2/nuxt/pages/download/[k].vue?macro=true";
import { default as erreurhqChJSEiL5Meta } from "/builds/datakode/hydraclic-v2/nuxt/pages/erreur.vue?macro=true";
import { default as formulaireXggkDRXAadMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/formulaire.vue?macro=true";
import { default as indexYNDF7B2k7aMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/index.vue?macro=true";
import { default as _91k_936pFqCq6BnLMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k].vue?macro=true";
import { default as controles_45simultanesrb977bueryMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/controles-simultanes.vue?macro=true";
import { default as formulaireN2lvI8EBgaMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/formulaire.vue?macro=true";
import { default as indexoGnbLMZdyTMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/index.vue?macro=true";
import { default as _91k_93ZQYhyStgcQMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k].vue?macro=true";
import { default as envoi_45de_45mailcBoHSzESuPMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail.vue?macro=true";
import { default as historique2vHTTtAKjfMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/historique.vue?macro=true";
import { default as formulaireKN9k3xi5t2Meta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/import/[k]/formulaire.vue?macro=true";
import { default as indexsDdGo94aShMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/import/[k]/index.vue?macro=true";
import { default as _91k_9373qJBGdVE7Meta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/import/[k].vue?macro=true";
import { default as importQAwOTXPONrMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/import.vue?macro=true";
import { default as formulaireDXyK4fgsQLMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/formulaire.vue?macro=true";
import { default as indexZr1k9Ys36sMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/index.vue?macro=true";
import { default as _91k_93ZC9uC1mVnNMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k].vue?macro=true";
import { default as indisponibilites_45programmeesVMnsAuae9HMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees.vue?macro=true";
import { default as formulaireZWXSBV3L7vMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/pei/[k]/formulaire.vue?macro=true";
import { default as indexGmKqjHmmxeMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/pei/[k]/index.vue?macro=true";
import { default as _91k_93oxH0hIbs42Meta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/pei/[k].vue?macro=true";
import { default as peirKnfSyoTUmMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/pei.vue?macro=true";
import { default as formulaireeaJaUHiinIMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/formulaire.vue?macro=true";
import { default as indexwxzPoZEfOZMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/index.vue?macro=true";
import { default as _91k_93g4QX9ryPDCMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/tournees/[k].vue?macro=true";
import { default as tournees8BivXmuiREMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/gestion/tournees.vue?macro=true";
import { default as index64ID67I7OKMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/index.vue?macro=true";
import { default as indexRYxn9J6LpbMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/profil/index.vue?macro=true";
import { default as profiltg3DUYxqaBMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/profil.vue?macro=true";
import { default as ressourcesBRhZiUPD5NMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/ressources.vue?macro=true";
import { default as tableau_45de_45bordwQqigG4jmJMeta } from "/builds/datakode/hydraclic-v2/nuxt/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: "administration-acteurs",
    path: "/administration/acteurs",
    meta: acteurs58H65761giMeta || {},
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/administration/acteurs.vue"),
    children: [
  {
    name: _91k_93B8sguhxbEfMeta?.name,
    path: ":k()",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/administration/acteurs/[k].vue"),
    children: [
  {
    name: "administration-acteurs-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/formulaire.vue")
  },
  {
    name: "administration-acteurs-k",
    path: "",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "administration-configuration",
    path: "/administration/configuration",
    meta: configurationUu1R32jE25Meta || {},
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/administration/configuration.vue")
  },
  {
    name: "administration-territoires",
    path: "/administration/territoires",
    meta: territoiresbBmwTWUlCtMeta || {},
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/administration/territoires.vue"),
    children: [
  {
    name: _91k_930W3AfJ8ubhMeta?.name,
    path: ":k()",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/administration/territoires/[k].vue"),
    children: [
  {
    name: "administration-territoires-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/administration/territoires/[k]/formulaire.vue")
  },
  {
    name: "administration-territoires-k",
    path: "",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/administration/territoires/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "administration-utilisateurs",
    path: "/administration/utilisateurs",
    meta: utilisateursDZsQpb389KMeta || {},
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/administration/utilisateurs.vue"),
    children: [
  {
    name: _91k_93ASY1cbfZBiMeta?.name,
    path: ":k()",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k].vue"),
    children: [
  {
    name: "administration-utilisateurs-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/formulaire.vue")
  },
  {
    name: "administration-utilisateurs-k",
    path: "",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "connexion",
    path: "/connexion",
    meta: indexx2zUqEHbe3Meta || {},
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/connexion/index.vue")
  },
  {
    name: mot_45de_45passe_45oublieQsopKuDiNSMeta?.name,
    path: "/connexion/mot-de-passe-oublie",
    meta: mot_45de_45passe_45oublieQsopKuDiNSMeta || {},
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie.vue"),
    children: [
  {
    name: "connexion-mot-de-passe-oublie-token",
    path: ":token()",
    meta: _91token_93frk1QGDX9AMeta || {},
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/[token].vue")
  },
  {
    name: "connexion-mot-de-passe-oublie",
    path: "",
    meta: indexomCXAG0Q0NMeta || {},
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/index.vue")
  }
]
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/contact.vue")
  },
  {
    name: "donnees-personnelles",
    path: "/donnees-personnelles",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/donnees-personnelles.vue")
  },
  {
    name: "download-k",
    path: "/download/:k()",
    meta: _91k_93Bu1faCdnx4Meta || {},
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/download/[k].vue")
  },
  {
    name: "erreur",
    path: "/erreur",
    meta: erreurhqChJSEiL5Meta || {},
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/erreur.vue")
  },
  {
    name: "gestion-controles-simultanes",
    path: "/gestion/controles-simultanes",
    meta: controles_45simultanesrb977bueryMeta || {},
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/controles-simultanes.vue"),
    children: [
  {
    name: _91k_936pFqCq6BnLMeta?.name,
    path: ":k()",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k].vue"),
    children: [
  {
    name: "gestion-controles-simultanes-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/formulaire.vue")
  },
  {
    name: "gestion-controles-simultanes-k",
    path: "",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-envoi-de-mail",
    path: "/gestion/envoi-de-mail",
    meta: envoi_45de_45mailcBoHSzESuPMeta || {},
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail.vue"),
    children: [
  {
    name: _91k_93ZQYhyStgcQMeta?.name,
    path: ":k()",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k].vue"),
    children: [
  {
    name: "gestion-envoi-de-mail-k-formulaire",
    path: "formulaire",
    meta: formulaireN2lvI8EBgaMeta || {},
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/formulaire.vue")
  },
  {
    name: "gestion-envoi-de-mail-k",
    path: "",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-historique",
    path: "/gestion/historique",
    meta: historique2vHTTtAKjfMeta || {},
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/historique.vue")
  },
  {
    name: "gestion-import",
    path: "/gestion/import",
    meta: importQAwOTXPONrMeta || {},
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/import.vue"),
    children: [
  {
    name: _91k_9373qJBGdVE7Meta?.name,
    path: ":k()",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/import/[k].vue"),
    children: [
  {
    name: "gestion-import-k-formulaire",
    path: "formulaire",
    meta: formulaireKN9k3xi5t2Meta || {},
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/import/[k]/formulaire.vue")
  },
  {
    name: "gestion-import-k",
    path: "",
    meta: indexsDdGo94aShMeta || {},
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/import/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-indisponibilites-programmees",
    path: "/gestion/indisponibilites-programmees",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees.vue"),
    children: [
  {
    name: _91k_93ZC9uC1mVnNMeta?.name,
    path: ":k()",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k].vue"),
    children: [
  {
    name: "gestion-indisponibilites-programmees-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/formulaire.vue")
  },
  {
    name: "gestion-indisponibilites-programmees-k",
    path: "",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-pei",
    path: "/gestion/pei",
    meta: peirKnfSyoTUmMeta || {},
    alias: ["/gestion/carte"],
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/pei.vue"),
    children: [
  {
    name: _91k_93oxH0hIbs42Meta?.name,
    path: ":k()",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/pei/[k].vue"),
    children: [
  {
    name: "gestion-pei-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/pei/[k]/formulaire.vue")
  },
  {
    name: "gestion-pei-k",
    path: "",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/pei/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-tournees",
    path: "/gestion/tournees",
    meta: tournees8BivXmuiREMeta || {},
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/tournees.vue"),
    children: [
  {
    name: _91k_93g4QX9ryPDCMeta?.name,
    path: ":k()",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/tournees/[k].vue"),
    children: [
  {
    name: "gestion-tournees-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/formulaire.vue")
  },
  {
    name: "gestion-tournees-k",
    path: "",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/index.vue")
  },
  {
    name: profiltg3DUYxqaBMeta?.name,
    path: "/profil",
    meta: profiltg3DUYxqaBMeta || {},
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/profil.vue"),
    children: [
  {
    name: "profil",
    path: "",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/profil/index.vue")
  }
]
  },
  {
    name: "ressources",
    path: "/ressources",
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/ressources.vue")
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    meta: tableau_45de_45bordwQqigG4jmJMeta || {},
    component: () => import("/builds/datakode/hydraclic-v2/nuxt/pages/tableau-de-bord.vue")
  }
]